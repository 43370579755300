<app-slide-in-right-modal
  iconImageSrc="assets/images/svgs/document--outline.svg"
  nextPath="/admin/customers"
  returnPath="/admin/customers"
>
  <p class="cta__text mb-5 text-sm text-mango-gray74">
    Kindly upload the following documents
  </p>

  <div
    class="inline-flex items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
  >
    <img
      src="assets/images/svgs/info-circle--yellow.svg"
      alt=""
      class="h-[1.5625rem] w-[1.5625rem] flex-1"
    />
    <p class="text-sm leading-[1.3125rem] text-mango-grey">
      Document should not be larger than 2MB. File types accepted include PDF,
      JPEG and PNG
    </p>
  </div>

  <div class="document__container my-5 flex flex-col gap-5">
    @for (
      document of documentList | keyvalue: originalOrder;
      track document.key;
      let i = $index
    ) {
      <div
        [ngClass]="{ 'pb-[0.62rem]': document.value.isAccordionOpen }"
        class="document-box flex flex-col gap-[0.62rem] rounded-xl border border-solid border-mango-seashell px-[0.62rem] pb-2 pt-4"
      >
        <div
          [ngClass]="{ 'pb-[0.62rem]': document.value.isAccordionOpen }"
          class="top document-type__wrapper flex cursor-pointer items-center justify-between"
          [id]="document.value.key"
        >
          <div class="left flex gap-[0.44rem]">
            <img
              [ngClass]="{ hidden: document.value.isUploaded }"
              src="assets/images/svgs/check-circle.svg"
              class="h-5 w-5"
              alt=""
            />

            <img
              [ngClass]="{ hidden: !document.value.isUploaded }"
              src="assets/images/svgs/check-circle--yellow.svg"
              class="h-5 w-5"
              alt=""
            />

            <span class="text-xs leading-5 text-black">{{
              document.value.inputLabel
            }}</span>
          </div>

          <!-- chevron icon -->
          <div class="right cursor-pointer">
            <img
              [ngClass]="{ 'rotate-180': document.value.isAccordionOpen }"
              src="assets/images/svgs/chevron-down-arrow.svg"
              alt=""
              srcset=""
              class="accordion--toggle-icon"
            />
          </div>
        </div>

        <div
          [ngClass]="{
            hidden: !document.value.isAccordionOpen,
            'is-open': document.value.isAccordionOpen
          }"
          class="bottom document-upload__wrapper w-full"
        >
          <div class="inner">
            <app-input-file
              [inputId]="document.value.id"
              [inputLabel]="document.value.inputLabel"
              (uploadedFileEvent)="getUploadedFile($event, document.value.key)"
              [maxFileSize]="document.value.maxFileSize"
              [acceptedFileTypes]="document.value.acceptedFileTypes"
            ></app-input-file>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="xl:grid xl:grid-cols-2">
    <div
      [ngClass]="{
        'cursor-not-allowed': uploadInProgress
      }"
      class="col-start-3 justify-self-end"
    >
      <button
        mango-button
        [routerLink]="['/admin/customers']"
        class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
      >
        <span *ngIf="uploadInProgress" spin></span>
        @if (!uploadInProgress) {
          Save and Continue
        }
      </button>
    </div>
  </div>
</app-slide-in-right-modal>
