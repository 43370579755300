import { CommonModule } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CentralDocumentUploadService } from '@core/services/central-document-upload.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { Subscription } from 'rxjs';
import { GeneralFileUploadService } from '@core/services/general-file-upload.service';
interface DocumentDetails {
  id: string;
  inputLabel: string;
  isUploaded: boolean;
  key: string;
  isAccordionOpen: boolean;
  documentFile: File | null;
  docIdentifier: string;
  maxFileSize: number;
  acceptedFileTypes: string[];
}
interface DocList {
  [key: string]: DocumentDetails;
}
@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [
    SlideInRightModalComponent,
    ButtonDirective,
    RouterLink,
    CommonModule,
    InputFileComponent,
    SpinDirective,
  ],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
})
export class DocumentsComponent implements AfterViewInit, OnInit, OnDestroy {
  acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
  public readonly originalOrder = (): number => 0;
  documentList: DocList = {
    signature: {
      id: 'signatureId',
      inputLabel: 'Signature',
      isUploaded: false,
      key: 'signature',
      documentFile: null,
      docIdentifier: 'signature',
      isAccordionOpen: true, //set the first document to be open by default
      maxFileSize: 2000,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    passport: {
      id: 'passportId',
      inputLabel: 'Passport Photograph',
      isUploaded: false,
      key: 'passport',
      documentFile: null,
      docIdentifier: 'passport_photograph',
      isAccordionOpen: false,
      maxFileSize: 2000,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    meansOfIdentification: {
      id: 'meansOfId',
      inputLabel: 'Means of Identification',
      isUploaded: false,
      key: 'meansOfIdentification',
      documentFile: null,
      docIdentifier: 'means_of_id',
      isAccordionOpen: false,
      maxFileSize: 2000,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    utilityBill: {
      id: 'utilityBillId',
      inputLabel: 'Utility Bill',
      isUploaded: false,
      key: 'utilityBill',
      documentFile: null,
      docIdentifier: 'utility_bill',
      isAccordionOpen: false,
      maxFileSize: 2000,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    generalKyc: {
      id: 'generalKycId',
      inputLabel: 'General KYC - 25MB (PDF)',
      isUploaded: false,
      key: 'generalKyc',
      documentFile: null,
      docIdentifier: 'general_kyc',
      isAccordionOpen: false,
      maxFileSize: 25000,
      acceptedFileTypes: ['application/pdf'],
    },
  };

  accountId!: string;
  subs: Subscription[] = [];
  uploadInProgress: boolean = false;

  activatedRoute = inject(ActivatedRoute);
  documentService = inject(CentralDocumentUploadService);
  toast = inject(NotificationFacade);
  generalFileUploadService = inject(GeneralFileUploadService);

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId =
      (this.activatedRoute.snapshot.queryParamMap.get(
        'account_id'
      ) as string) ?? this.activatedRoute.snapshot.queryParams['accountId'];
  }

  ngAfterViewInit(): void {
    // we attach the click event this way since the HTML is dynamically generated
    for (const key in this.documentList) {
      this.renderer.listen(
        this.elRef.nativeElement.querySelector(`#${key}`),
        'click',
        () => this.openAccordion(key)
      );
    }
  }

  getUploadedFile(fileList: FileList | null, documentType: string): void {
    if (fileList) {
      this.documentList[documentType].isUploaded = true;
      this.documentList[documentType].documentFile = fileList[0];
      // this.uploadDocument(documentType);
      documentType === 'generalKyc'
        ? this.uploadGeneralFile()
        : this.uploadDocument(documentType);
    } else {
      this.documentList[documentType].isUploaded = false;
      this.documentList[documentType].documentFile = null;
    }
  }

  openAccordion(documentType: string): void {
    this.documentList[documentType].isAccordionOpen =
      !this.documentList[documentType].isAccordionOpen;

    this.cdRef.detectChanges();
  }

  uploadDocument(docType: string) {
    const formData = new FormData();
    const documentFile = this.documentList[docType].documentFile;
    if (documentFile && this.accountId) {
      formData.append('file', documentFile);
      formData.append(
        'document_type',
        this.documentList[docType].docIdentifier
      );
      formData.append('account_id', this.accountId);
    }

    const sub = this.documentService.uploadKycDocument(formData).subscribe({
      next: (response: HttpEvent<any>) => {
        this.uploadInProgress = false;
        this.cdRef.detectChanges();

        if (response.type === HttpEventType.Response) {
          this.toast.success(`Successfully uploaded Document`);
        }
      },
      error: (response) => {
        this.toast.error('Failed to upload Document');
      },
    });

    this.subs.push(sub);
  }

  async uploadGeneralFile(): Promise<void> {
    const generalKey = 'generalKyc';

    if (!this.documentList['generalKyc'].documentFile) {
      this.toast.error('No file selected');
      return;
    }

    try {
      // Get the presigned URL from the service
      const presignedUrl = await this.generalFileUploadService.getPresignedUrl(
        this.accountId
      );

      const formData = new FormData();
      const documentFile = this.documentList[generalKey].documentFile;
      if (documentFile) {
        formData.append('file', documentFile);
        formData.append(
          'document_type',
          this.documentList[generalKey].docIdentifier
        );
        formData.append('account_id', this.accountId);
      }

      // Use the presigned URL to upload the file
      await this.generalFileUploadService.uploadFile(presignedUrl, formData);

      this.toast.success('Successfully uploaded General KYC Document');
    } catch (err) {
      this.toast.error(
        'Failed to upload the General KYC Document. Please try again.'
      );
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
